<template>
    <div class="spinner-wrapper mt-2 mb-2 ml-2 mr-2">
        <div class="spinner"><b-spinner variant="primary" /></div>

        <p v-if="msg != ''">
          {{msg}}
        </p>

        <!--
        <p>
            Tutta la tranquillità di prenotare con VadoBay
        </p>
        <b-container fluid>
          <PrefooterWhite />
        </b-container>
        -->
    </div>
</template>

<script>
//import PrefooterWhite from '../blocks/common/prefooter-white'

export default {
    name: 'spinner',
    components:
    {
      //PrefooterWhite
    },
    props: {
      msg: {
        type: String,
        default: '',
      },
    },
}
</script>

<style lang="scss">
    .spinner-border
    {
        width:4rem !important;
        height:4rem !important;
    }
    .spinner-wrapper
    {
        display:block;
        width:100%;
        text-align:center;
    }
    .spinner
    {
        margin-bottom:1rem;
    }
    .after-spinner
    {
        width:100%;
        text-align: center;
    }
</style>