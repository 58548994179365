<template>
    <div class="postSlider" v-if="!loading">
        <p class="sliderHeadingBordered">
            <span class="common-title">Le scelte dello staff</span>
            <br>
            Segnalazioni da chi naviga e viaggia per professione
        </p>
        <div class="slick">
            <Slick ref="slick" :options="slickOptions">
                <div
                    v-for="(post,index) in posts"
                    :key="'slide-post-'+index"
                >
                    <div class="postPad">
                    <router-link :to="{
                                            name: 'post',
                                            params: {
                                                postId: post.postId,
                                                title: post.slug,
                                            }
                                        }">
                        <div
                            class="postPad__thumbnail"
                            :style="'background-image:url('+post.featuredMedia.src+');'"
                        >
                        </div>
                    </router-link>

                    <div class="postPad__content">
                        <div>
                        <div class="postPad__title">{{post.title}}
                        </div>
                        <div class="postPad__excerpt" v-html="post.excerpt"></div>
                        </div>

                        <div class="postPad__bottom">
                            <div class="postPad__link">
                                <router-link :to="{
                                                    name: 'post',
                                                    params: {
                                                        postId: post.postId,
                                                        title: post.slug,
                                                    }
                                                }">
                                Scopri di più
                                </router-link>
                            </div>
                            <div class="postPad__date">{{post.readableDate}}</div>
                        </div>
                    </div>
                    </div>
                </div>
            </Slick>
            <span class="slick-arrow" id="slick-prev-post" :prev="_uid">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
            <span class="slick-arrow" id="slick-next-post" :next="_uid">
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.6" d="M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z" fill="white"/>
                <path d="M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766" stroke="#333333" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </span>
        </div>
    </div>
    <div class="postSlider loading" v-else>
        <Spinner />
    </div>
</template>

<script>
import Api from '../../api'
import Spinner from '../atoms/spinner'
import Slick from 'vue-slick';

export default {
  name: 'post-slider',
  components: {
    Spinner,
    Slick,
  },
  props:
      {
        category: Number,
        exclude:
            {
              type: Array,
              default: function () { return [] }
            }
      },
  data() {
    return {
      loading: true,
      posts: Array(),
      slickOptions: {
        autoplay: (this.$config.guiSettings.autoplaySpeedPosts != 0),
        autoplaySpeed: this.$config.guiSettings.autoplaySpeedPosts,
        //vertical: true,
        //verticalSwiping: true,
        slidesToShow: 3,
        prevArrow: '[prev='+this._uid+']',
        nextArrow: '[next='+this._uid+']',
        adaptiveHeight:true,
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              //adaptiveHeight:false,
              centerMode: false,
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false,
              //variableWidth: true,
              //vertical: false,
              //verticalSwiping: false,
            }
          },
          {
            breakpoint: 992,
            settings: {
              //adaptiveHeight:false,
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              //variableWidth: true,
              //vertical: false,
              //verticalSwiping: false,
            }
          }
        ]
      },

    }
  },
  mounted()
  {
    let _this = this
    _this.loading = true

    Api.getPosts({
      category: _this.category,
    })
        .then((results) => {
          _this.exclude.forEach(function(id){
            //ora è un array, delete non va più bene
            //delete results.posts[id]
            results.posts = results.posts.filter( obj => obj.postId !== id)
          })
          _this.posts = results.posts
          _this.loading = false
        })
  },
  methods:{
  }
}
</script>

<style lang="scss" scoped>
    
    
    
    

    // vedi _postSlider.scss
    .postPad__content
    {
        margin:$grid-gutter-width/4;
        @include media-breakpoint-up(lg)
        {
            margin:$grid-gutter-width/2;
        }
    }
    .sliderHeadingBordered
    {
        @include customize-bordered-element( "primary" );
    }
    .postPad__bottom
    {
        display:flex;
        justify-content: space-between;
        align-items:center;
        margin-top:1rem;
        .postPad__date
        {
            margin:0 !important;
            padding:0;   
            padding-left:0.25rem;         
            font-weight:400;
        }
        .postPad__link
        {
            margin:0 !important;
            padding:0;
            padding-right:0.25rem;
        }
    }
</style>