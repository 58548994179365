var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',{staticClass:"postSlider"},[_vm._m(0),_c('div',{staticClass:"slick"},[_c('Slick',{ref:"slick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.posts),function(post,index){return _c('div',{key:'slide-post-'+index},[_c('div',{staticClass:"postPad"},[_c('router-link',{attrs:{"to":{
                                        name: 'post',
                                        params: {
                                            postId: post.postId,
                                            title: post.slug,
                                        }
                                    }}},[_c('div',{staticClass:"postPad__thumbnail",style:('background-image:url('+post.featuredMedia.src+');')})]),_c('div',{staticClass:"postPad__content"},[_c('div',[_c('div',{staticClass:"postPad__title"},[_vm._v(_vm._s(post.title)+" ")]),_c('div',{staticClass:"postPad__excerpt",domProps:{"innerHTML":_vm._s(post.excerpt)}})]),_c('div',{staticClass:"postPad__bottom"},[_c('div',{staticClass:"postPad__link"},[_c('router-link',{attrs:{"to":{
                                                name: 'post',
                                                params: {
                                                    postId: post.postId,
                                                    title: post.slug,
                                                }
                                            }}},[_vm._v(" Scopri di più ")])],1),_c('div',{staticClass:"postPad__date"},[_vm._v(_vm._s(post.readableDate))])])])],1)])}),0),_c('span',{staticClass:"slick-arrow",attrs:{"id":"slick-prev-post","prev":_vm._uid}},[_c('svg',{attrs:{"width":"60","height":"60","viewBox":"0 0 60 60","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.6","d":"M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z","fill":"white"}}),_c('path',{attrs:{"d":"M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766","stroke":"#333333","stroke-width":"2","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('span',{staticClass:"slick-arrow",attrs:{"id":"slick-next-post","next":_vm._uid}},[_c('svg',{attrs:{"width":"60","height":"60","viewBox":"0 0 60 60","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.6","d":"M30 59.9992C46.5685 59.9992 60 46.5681 60 29.9996C60 13.4311 46.5685 0 30 0C13.4315 0 0 13.4311 0 29.9996C0 46.5681 13.4315 59.9992 30 59.9992Z","fill":"white"}}),_c('path',{attrs:{"d":"M33.5758 43.5237L20.2078 30.0001L33.5758 16.4766","stroke":"#333333","stroke-width":"2","stroke-miterlimit":"10","stroke-linecap":"round","stroke-linejoin":"round"}})])])],1)]):_c('div',{staticClass:"postSlider loading"},[_c('Spinner')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"sliderHeadingBordered"},[_c('span',{staticClass:"common-title"},[_vm._v("Le scelte dello staff")]),_c('br'),_vm._v(" Segnalazioni da chi naviga e viaggia per professione ")])}]

export { render, staticRenderFns }