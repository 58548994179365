<template>
  <div
    class="post postWp mb-4 pb-4"
    v-if="!loading"
  >
    <div id="postHero">
      <div class="postHero-image"><img :src="post.featuredMedia.src" /></div>
      <div class="postHero-content">
        <b-container>
          <div class="postHero-wrapper">
            <div @click="mixinGoTo('blog',{})">
              <BtnBack :text="'Torna a BLOG - La scelta dello staff'" />
            </div>
            <h3 class="postHero-title">{{post.title}}</h3>
            <p class="postDate">{{post.readableDate}}</p>
          </div>
        </b-container>
      </div>
    </div>
    <b-container>
      <div
        class="post__content"
        v-html="post.content"
      ></div>
      <br>
    </b-container>

    <b-container>
      <PostSlider
        :category="$config.posts.category"
        :exclude="[postId]"
      />
    </b-container>

  </div>
  <div
    class="post loading"
    v-else
  >
    <Spinner />
  </div>
</template>

<script>
//import Vue from 'vue'
import Api from '@/api'
import PostSlider from '@/components/blocks/post-slider'
import Spinner from '@/components/atoms/spinner'
import BtnBack from '@/components/atoms/btn-back'

export default {
  name: 'post',
  metaInfo() {
    return {
      title: (this.post.title) ? this.post.title : 'post',
      meta: [
        { vmid: 'robots', name: 'robots', content: this.metaRobots },

        (this.post.title) ? { vmid: 'metaTitle', name: 'Title', content: this.post.title } : {},

        (this.post.excerpt) ? { vmid: 'metaDescription', name: 'Description', content: this.post.excerpt } : {},

        (this.post.title) ? { vmid: 'ogTitle', name: 'og:title', content: this.post.title } : {},

        (this.post.excerpt) ? { vmid: 'ogDescription', name: 'og:description', content: this.post.excerpt } : {},

        (this.post.featuredMedia) ? { vmid: 'ogImage', name: 'og:image', content: this.post.featuredMedia.src } : {},

        (this.post.featuredMedia) ? { vmid: 'ogImageWidth', name: 'og:width', content: this.post.featuredMedia.width } : {},

        (this.post.featuredMedia) ? { vmid: 'ogImageHeight', name: 'og:height', content: this.post.featuredMedia.height } : {},

      ]
    }
  },
  components: {
    Spinner,
    PostSlider,
    BtnBack,
  },
  props:
  {
    postId:
    {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      loading: true,
      post: {},
      metaRobots: process.env.VUE_APP_META_ROBOTS,
    }
  },
  watch: {
    postId: function () {
      this.getPost()
    }
  },
  mounted() {
    this.getPost()
  },
  methods: {
    getPost() {
      let _this = this
      _this.loading = true

      Api.getPosts({
        postId: _this.postId,
      })
        .then((results) => {
          _this.loading = false

          if (results.posts) {

            // prima arrivava un oggetto con altri oggetti indicizzati, ora arriva un array
            //_this.post = results.posts[_this.postId]
            _this.post = results.posts[0]
          } else {
            // se non ritorna, errore o post rimosso, setto noindex per deincizzare
            _this.metaRobots = 'noindex'

            this.mixinGoTo('not-found', {})

          }


        })
    }
  }
}
</script>

<style lang="scss">
.post {

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 250px;
  }

  .postHero-wrapper {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    & > * {
      min-width: 100%;
    }
    .btn-back {
      display: inline-flex;
      padding-bottom: 2rem;
      svg {
        margin-right: 1rem !important;
      }
    }
  }

  .post__content {
    p {
      padding-top: 0.05rem;
    }
    img {
      border-radius: 25px;
      //margin-bottom:1rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @include customize-color("primary");
    }
    a {
      @include customize-color("cta");
    }
    blockquote {
      @include customize-color("primary");
      font-size: 1.5rem;
      text-align: center;
    }
  }

  .alignleft {
    float: left;
    margin-right: 1.5em;
  }
  .alignright {
    float: right;
    margin-left: 1.5em;
  }
  .post__content {
    max-width: 830px;
    margin: auto;
    * {
      font-family: $font-family-base !important;
    }
  }
  #postHero {
    margin-top: $page-header-mobile-height;
    display: flex;
    flex-direction: column;

    .postHero-slide {
    }

    .postHero-image {
      flex: 1;
      min-width: 100%;
    }

    .postHero-content {
      flex: 1;
      min-width: 100%;

      margin: 2rem auto;
      * {
        margin: 0 auto;
      }
    }

    .postHero-title {
      @include customize-color("primary");
      font-weight: 900;
    }

    .postDate {
      display: block;
      text-align: right;
      max-width: 90%;
      margin: auto;
      margin-top: 2rem;
    }

    .postHero-slide {
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-bottom: $grid-gutter-width/2;
      margin-top: 0;
      .postHero-image {
        flex: 50%;
        min-width: 50%;
      }
      .postHero-content {
        flex: 50%;
        min-width: 50%;
        .container {
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
      .postHero-title {
        font-size: 26px;
        max-width: 400px;
      }
    }
    @include media-breakpoint-up(xl) {
      .postHero-title {
        font-size: 30px;
        max-width: 500px;
      }
    }
    @include media-breakpoint-up(xxl) {
      margin-bottom: $grid-gutter-width;
      .postHero-title {
        font-size: 40px;
        max-width: 700px;
      }
    }

    /*
            .hero__slide
            {
                padding:0;

                display:flex;
                flex-direction: column;
            }
            
            .hero__slide-image
            {
                order:2;
            }

            .hero__slide-content
            {
                order:1;
            }

            .hero__slide-wrapper
            {
                max-width: 100%;
                position: relative;
                right: auto;
                top: auto;
                transform: none;
                //background: rgba(0, 0, 0, 0.45);
                //padding: 1.5rem 2rem 1.75rem 2rem;
                @include media-breakpoint-down(lg)
                {
                    display: block;
                    *
                    {
                        font-size: 20px;
                        text-align:center;
                        margin-bottom:0;
                    }
                }
            }
            */

    /*
            .hero__slide-content
            {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
            }
            */
    img {
      width: 100%;
    }
  }
}
</style>